import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["classNames", "hits", "itemComponent", "sendEvent", "emptyComponent", "banner", "bannerComponent"];
import { cx } from "../lib/index.js";

// Should be imported from a shared package in the future

function createDefaultBannerComponent(_ref) {
  var createElement = _ref.createElement;
  return function DefaultBanner(_ref2) {
    var _banner$link;
    var classNames = _ref2.classNames,
      banner = _ref2.banner;
    if (!banner.image.urls[0].url) {
      return null;
    }
    return createElement("aside", {
      className: cx('ais-Hits-banner', classNames.bannerRoot)
    }, (_banner$link = banner.link) !== null && _banner$link !== void 0 && _banner$link.url ? createElement("a", {
      className: cx('ais-Hits-banner-link', classNames.bannerLink),
      href: banner.link.url,
      target: banner.link.target
    }, createElement("img", {
      className: cx('ais-Hits-banner-image', classNames.bannerImage),
      src: banner.image.urls[0].url,
      alt: banner.image.title
    })) : createElement("img", {
      className: cx('ais-Hits-banner-image', classNames.bannerImage),
      src: banner.image.urls[0].url,
      alt: banner.image.title
    }));
  };
}
export function createHitsComponent(_ref3) {
  var createElement = _ref3.createElement,
    Fragment = _ref3.Fragment;
  var DefaultBannerComponent = createDefaultBannerComponent({
    createElement: createElement,
    Fragment: Fragment
  });
  return function Hits(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      hits = userProps.hits,
      ItemComponent = userProps.itemComponent,
      sendEvent = userProps.sendEvent,
      EmptyComponent = userProps.emptyComponent,
      banner = userProps.banner,
      BannerComponent = userProps.bannerComponent,
      props = _objectWithoutProperties(userProps, _excluded);
    return createElement("div", _extends({}, props, {
      className: cx('ais-Hits', classNames.root, hits.length === 0 && cx('ais-Hits--empty', classNames.emptyRoot), props.className)
    }), banner && (BannerComponent ? createElement(BannerComponent, {
      className: cx('ais-Hits-banner', classNames.bannerRoot),
      banner: banner
    }) : createElement(DefaultBannerComponent, {
      classNames: classNames,
      banner: banner
    })), hits.length === 0 && EmptyComponent ? createElement(EmptyComponent, null) : createElement("ol", {
      className: cx('ais-Hits-list', classNames.list)
    }, hits.map(function (hit, index) {
      return createElement(ItemComponent, {
        key: hit.objectID,
        hit: hit,
        index: index,
        className: cx('ais-Hits-item', classNames.item),
        onClick: function onClick() {
          sendEvent('click:internal', hit, 'Hit Clicked');
        },
        onAuxClick: function onAuxClick() {
          sendEvent('click:internal', hit, 'Hit Clicked');
        }
      });
    })));
  };
}